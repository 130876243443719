import { useEffect, useLayoutEffect, useRef, useState } from "react";
import "./App.css";

const useWindowSize = () => {
  const [size, setSize] = useState([0, 0]);
  useLayoutEffect(() => {
    const updateSize = () => {
      setSize([window.innerWidth, window.innerHeight]);
    }
    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, []);
  return size;
}

function App() {

  const publicUrl = `/frames/Reel`;
  const numberOfFile = 371;
  let currentId = 0;

  const canvasRef = useRef(null);
  const updateRef = useRef(false);

  const [imageList, setImageList] = useState({});
  const [initDone, setInitDone] = useState(false);
  const [displayText, setDisplayText] = useState({ display: true, content: <></> });
  const [showCanvas, setShowCanvas] = useState(false);
  const [loaderDone, setLoaderDone] = useState(false);
  const [fadeinDone, setFadeinDone] = useState(false);

  const [width, height] = useWindowSize();

  const sleep = ms => new Promise(r => setTimeout(r, ms));

  useEffect(() => {
    if (width !== 0) {
      if (canvasRef.current) {
        listenToScroll(canvasRef.current.getContext("2d"));
      }
    }
  }, [width, height]);

  function sendMail() {
    window.open("mailto:saintlo.alexandre@gmail.com?subject=Alexandre votre site est fantastique&body=Faites confiance à votre plume, lancez-vous.");
  }

  function getCV() {
    window.open("/CV-Alexandre-Saintlo.pdf", "_blank");
  }

  const texts = {
    firstText: {
      direction: "right",
      content: [{ text: "Défilez vers le bas   " }, { text: "pour découvrir   " }, { text: "mon profil ↓   " }],
    },
    SecondTextLeft: {
      direction: "left",
      content: [{ text: "Convaincu ?   " }],
    },
    SecondTextRight: {
      direction: "right",
      content: [{ text: "Convaincu ?   " }],
    },
    ThirdText: {
      direction: "left",
      content: [{ text: "Téléchargez mon <CV/   " }, { text: "ou envoyez-moi   " }, { text: "un <message/   " }],
      onClicks: { 0: () => getCV(), 2: () => sendMail() }
    },
  }


  const handleDigits = (n) => {
    const digit = 3;
    const nLength = n.toString().length;
    var s = "0".repeat(digit - nLength) + n;
    return s;
  }


  function toDataURL(url, callback) {
    var xhr = new XMLHttpRequest();
    xhr.onload = function () {
      var reader = new FileReader();
      reader.onloadend = function () {
        callback(reader.result);
      }
      reader.readAsDataURL(xhr.response);
    };
    xhr.open("GET", url);
    xhr.responseType = "blob";
    xhr.send();
  }


  const scaleToFit = (img, canvas, canvasContext, id) => {
    // get the scale
    var scale = Math.min(canvas.width / img.width, canvas.height / img.height);
    // get the top left position of the image
    var x = (canvas.width / 2) - (img.width / 2) * scale;
    var y = (canvas.height / 2) - (img.height / 2) * scale;

    canvasContext.drawImage(img, x, y, (img.width) * scale, (img.height) * scale);

    currentId = id;
    updateRef.current = false;
  }

  const updateImage = async ({ nextFrameId, canvasContext, localCanvasRef }) => {
    let img = new Image;
    img.onload = async function () {
      canvasContext.clearRect(0, 0, localCanvasRef.width, localCanvasRef.height);
      scaleToFit(img, localCanvasRef.current, canvasContext, nextFrameId);
    };
    if (Object.keys(imageList).length) {
      let b64Image = imageList[nextFrameId];
      if (!b64Image) {
        const currentImageUrl = `${publicUrl}${nextFrameId >= 100 ? nextFrameId : nextFrameId >= 10 ? `0${nextFrameId}` : `00${nextFrameId}`}.jpg`;
        toDataURL(currentImageUrl, function (b64) {
          const localImage = { [nextFrameId]: b64 };
          setImageList({ ...imageList, ...localImage });
          img.src = b64;
        })
      } else {
        img.src = b64Image;
      }
    }
  }

  const preloadImages = async () => {
    let localImageContainer = {};
    for (let i = 1; i <= numberOfFile; i++) {
      if (i % 50 === 0) {
        await sleep(700);
      }
      const currentImageUrl = `${publicUrl}${handleDigits(i)}.jpg`;
      toDataURL(currentImageUrl, function (b64) {
        localImageContainer = { ...localImageContainer, ...{ [i]: b64 } };
        if (i === numberOfFile) { setImageList(localImageContainer); setLoaderDone(true); }
      })
    }
  };

  const addAnchors = ({ text, onClick }) => {
    const lengthStart = text.replace(/[^<]/g, "").length;
    const lengthEnd = text.replace(/[^/]/g, "").length;

    if (lengthStart === 0) {
      return text;
    }

    let anchorText;
    let normalText;

    if ((lengthStart + lengthEnd) % 2 !== 0) {
      anchorText = text.substring(
        text.indexOf("<") + 1,
        text.length
      );
      normalText = text.replace("<" + anchorText, "");
    } else {
      anchorText = text.substring(
        text.indexOf("<") + 1,
        text.lastIndexOf("/")
      );
      normalText = text.replace("<" + anchorText + "/", "");
    }

    return <span>{normalText}<a onClick={onClick ? onClick : () => { }}>{anchorText}</a></span>
  }

  const trimText = ({ direction, currentText, textIndex, onClick }) => {
    const normalIndex = direction === "right" ? currentText.length - textIndex : textIndex;
    let displayedText = addAnchors({ text: currentText.substring(0, normalIndex), onClick: onClick });

    return { displayText: displayedText };
  }

  const handleAnim = ({ id, text, pagePercentage, startingFrame, numberOfFrame, canvasState }) => {
    setShowCanvas(canvasState);

    const currentText = text.content[id];
    const tempPagePercentage = (pagePercentage - startingFrame);
    const temp = Math.abs((tempPagePercentage / numberOfFrame));
    const currentTextLength = currentText.text.length;
    const textIndex = parseInt(temp * currentTextLength);
    const trimmedText = trimText({ ...{ direction: text.direction, currentText: currentText.text, textIndex: textIndex }, ...((text.onClicks && text.onClicks[id]) && { onClick: text.onClicks[id] }) })
    const normalText = currentText.text.replace("<", "").replace("/", "");

    if (!fadeinDone && (pagePercentage >= 3 && pagePercentage <= 6)) {
      setFadeinDone(true)
    }

    if (width !== 0 && trimmedText.displayText !== "") {
      setDisplayText(
        {
          display: true,
          fadeIn: pagePercentage <= 3,
          displayWaterMark: pagePercentage >= 98.8,
          onClicks: text.onClicks,
          content:
            text.content.map(
              (item, index) =>
                index === id
                  ?
                  <>
                    <div style={{ margin: "auto", textAlign: "left", display: "inline-block", maxHeight: "9vw", overflow: "hidden" }}>
                      <span>{trimmedText.displayText}</span>
                      <p className="invisible">{normalText}</p>
                    </div>
                    <br />
                  </>
                  : index > id
                    ? <><span ></span><br /></>
                    : <><span >{addAnchors({ text: item.text, onClick: text.onClicks && text.onClicks[index] })}</span><br /></>
            )
        }
      );
    }
  }

  const listenToScroll = async () => {
    const winScroll =
      document.body.scrollTop || document.documentElement.scrollTop

    const height =
      document.documentElement.scrollHeight -
      document.documentElement.clientHeight

    const scrolled = winScroll / height;
    const numberOfFrameCanvas = numberOfFile;
    const localContext = canvasRef.current.getContext("2d");
    const pagePercentage = parseFloat((scrolled * 100).toFixed(1));

    if (pagePercentage <= 3) {
      handleAnim({ id: 2, text: texts.firstText, pagePercentage: pagePercentage, startingFrame: 0, numberOfFrame: 3, canvasState: false })
    } else if (pagePercentage >= 3 && pagePercentage <= 6) {
      handleAnim({ id: 1, text: texts.firstText, pagePercentage: pagePercentage, startingFrame: 3, numberOfFrame: 6 - 3, canvasState: false })
    } else if (pagePercentage >= 6 && pagePercentage <= 9) {
      handleAnim({ id: 0, text: texts.firstText, pagePercentage: pagePercentage, startingFrame: 6, numberOfFrame: 9 - 6, canvasState: false })
    }
    if (pagePercentage >= 10 && pagePercentage <= 76) {
      setDisplayText({ display: false, content: <></> });
      setShowCanvas(true);

      const tempPagePercentage = (pagePercentage - 10);
      const temp = (tempPagePercentage / (76 - 11)) * 100;
      //used to prevent the scroll to go to far and thus setting a nextFrameId that would be out of scrope
      const tempNextFrameId = Math.round(numberOfFrameCanvas * (temp / 100));
      const nextFrameId = (tempNextFrameId !== 0 && tempNextFrameId <= numberOfFile) ? tempNextFrameId : numberOfFile;

      if ((!updateRef.current || tempPagePercentage < 65) && (currentId !== nextFrameId)) {
        updateRef.current = true;
        nextFrameId && requestAnimationFrame(() => updateImage({ nextFrameId: nextFrameId, canvasContext: localContext, localCanvasRef: canvasRef }))
      }
    }

    if (pagePercentage >= 72.4 && pagePercentage <= 81.2) {
      handleAnim({ id: 0, text: texts.SecondTextLeft, pagePercentage: pagePercentage, startingFrame: 72.4, numberOfFrame: 72.4 - 81.2, canvasState: pagePercentage >= 76 ? false : true })
    }

    if (pagePercentage >= 81.2 && pagePercentage <= 90) {
      handleAnim({ id: 0, text: texts.SecondTextRight, pagePercentage: pagePercentage, startingFrame: 81.2, numberOfFrame: 81.2 - 90, canvasState: false })
    }

    else if (pagePercentage >= 90 && pagePercentage <= 93) {
      handleAnim({ id: 0, text: texts.ThirdText, pagePercentage: pagePercentage, startingFrame: 90, numberOfFrame: 90 - 93, canvasState: false })
    } else if (pagePercentage >= 93 && pagePercentage <= 96) {
      handleAnim({ id: 1, text: texts.ThirdText, pagePercentage: pagePercentage, startingFrame: 93, numberOfFrame: 93 - 96, canvasState: false })
    } else if (pagePercentage >= 96 && pagePercentage <= 100) {
      handleAnim({ id: 2, text: texts.ThirdText, pagePercentage: pagePercentage, startingFrame: 96, numberOfFrame: 96 - 100, canvasState: false })
    }

  }

  useEffect(() => {
    if (canvasRef.current) {
      preloadImages();
    }

    const loadHandler = () => {
      window.scrollTo(1, 0);
    }

    window.addEventListener("load", () => loadHandler());

    return () => {
      window.addEventListener("scroll", () => listenToScroll());
      window.addEventListener("load", () => loadHandler());

    }
  }, [])

  useEffect(() => {
    if (Object.keys(imageList).length !== 0 && !initDone && loaderDone) {

      const localCanvasRef = canvasRef.current;
      localCanvasRef.width = 1158 * 4;
      localCanvasRef.height = 770 * 4;

      window.addEventListener("scroll", () => listenToScroll());
      listenToScroll(canvasRef.current.getContext("2d"));
      setInitDone(true)
    }
  }, [imageList])

  return (
    <div className="App" style={{ height: loaderDone ? "2500vh" : "100vh", overflow: loaderDone ? "scroll" : "hidden", fontFamily: "Inter", scrollBehavior: "smooth" }}>
      <div style={{
        width: "13.5em", textAlign: "center", margin: "auto", color: "white", position: "fixed", top: "50%", left: "0", transform: "translateY(-50%)", right: 0,
        display: displayText.display !== false ? "block" : "none", zIndex: 2,
      }}>
        <div style={{ textAlign: "center", width: "13.5em", height: 168, maxHeight: 168, minHeight: 168, margin: "auto", display: "table-cell", verticalAlign: "middle" }}>
          {loaderDone ?
            displayText.display &&
              Array.isArray(displayText.content)
              ?
              <div className={displayText.fadeIn && !fadeinDone ? "fadein-anination" : ""} style={{ margin: "auto", display: "inline-block", overflowY: "hidden", width: width <= 710 ? "80vw" : "35.95vw", overflowX: "visible" }}>
                {displayText.content.map((item, index) => <div key={index}>{item}</div>)}
              </div>
              :
              <></>
            :
            <>
              <p className="first-loading-text">Deux petites secondes</p>
              <p className="second-loading-text">J’arrive dans votre navigateur</p>
            </>
          }
        </div>
      </div>
      <div>
        <canvas ref={canvasRef} className="canvasRef" style={{ display: (showCanvas) ? "block" : "none", zIndex: 1 }} />
      </div>
      <div>
        {displayText.displayWaterMark && <p className="watermark">Développé par Anthony Royer</p>}
      </div>
    </div>
  );
}

export default App;
